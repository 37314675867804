<template>
  <div>
    <h2 class="view-header">Associate Pay Codes</h2>
    <a-modal
      v-if="visibleAssociatePayCodeModal"
      v-model="visibleAssociatePayCodeModal"
      width="1000px"
      :footer="null"
      @cancel="closeAssociatePayCodeModal"
    >
      <associate-pay-code
        :shiftId="selectedShiftId"
        @on-add="handleAddOrEditAssociatePayCode"
        @on-edit="handleAddOrEditAssociatePayCode"
        @on-cancel="closeAssociatePayCodeModal"
      />
    </a-modal>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params"></div>
        <div class="table_predefined_filters">
          <a-button type="primary" @click="addCode">Add</a-button>
          <a-select
            show-search
            v-model="selectedAssociateNumber"
            :allowClear="true"
            placeholder="Associate (Name/ID)"
            style="min-width: 180px"
            :filter-option="false"
            :not-found-content="null"
            :dropdown-match-select-width="false"
            :disabled="loading"
            :options="foundAssociates"
            @search="fetchUsers"
            @change="handleSearchUsersChange"
          />

          <a-date-picker
            v-model="dateFrom"
            format="MM/DD/YYYY"
            placeholder="From"
            :disabled-date="disabledStartDate"
          />
          <a-date-picker
            v-model="dateTo"
            format="MM/DD/YYYY"
            placeholder="To"
            :disabled-date="disabledEndDate"
          />
        </div>
      </div>
    </div>
    <a-table
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      row-key="shift.id"
      @change="handleTableChange"
    >
      <span
        slot="associate"
        slot-scope="text, record"
        :key="record.associate.photo_url"
      >
        <grouped-associate-info
          :key="record.shift.id"
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
        />
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-tooltip>
            <template slot="title"> Edit Code </template>
            <a-button icon="edit" @click="editCode(record.shift.id)" />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> Delete Code </template>
            <a-button icon="delete" @click="deleteCode(record.shift.id)" />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import antd, {
  Button,
  DatePicker,
  Dropdown,
  notification,
  Modal,
  Select,
  Table,
  Tooltip,
} from "ant-design-vue";
import api from "@/api";
import moment from "moment-timezone";
import store from "@/store";
import Util from "@/util";
import AssociatePayCode from "@/views/associate-pay-code.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";

export default {
  components: {
    AssociatePayCode,
    "a-date-picker": DatePicker,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-dropdown": Dropdown,
    "a-select": Select,
    "a-table": Table,
    "a-modal": Modal,
    "a-tooltip": Tooltip,
    "grouped-associate-info": GroupedAssociateInfo,
  },
  watch: {
    dateFrom() {
      this.sendToFirstPage();
      this.loadData();
    },
    dateTo() {
      this.sendToFirstPage();
      this.loadData();
    },
    selectedAssociateNumber() {
      this.sendToFirstPage();
      this.loadData();
    },
  },
  data() {
    return {
      tableData: [],

      loading: false,

      foundAssociates: [],
      selectedAssociateNumber: undefined,
      searchUsersTimeout: 0,

      dateFrom: undefined,
      dateTo: undefined,

      serverDateFormat: store.state.applicationState.serverDateFormat,

      visibleAssociatePayCodeModal: false,
      selectedShiftId: undefined,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          key: "shift_id",
          sorter: true,
        },
        {
          title: "Associate Number",
          dataIndex: "associate.associate_number",
          key: "associate_number",
          sorter: true,
        },
        {
          title: "Associate",
          scopedSlots: { customRender: "associate" },
          key: "associate_name",
          sorter: true,
        },
        {
          title: "Job Classification",
          dataIndex: "associate.role.name",
          key: "role_name",
          sorter: true,
        },
        {
          title: "Date",
          dataIndex: "shift.date",
          key: "date",
          sorter: true,
        },
        {
          title: "Code",
          dataIndex: "shift.pay_code.name",
          key: "pay_code_name",
          sorter: true,
        },
        {
          title: "Type",
          dataIndex: "shift.pay_code.type",
          key: "shift.pay_code.type",
        },
        {
          title: "Value",
          dataIndex: "shift.hour_duration",
          key: "shift.hour_duration",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        pageSize: 50,
        total: 0,
        current: 1,
      },
    };
  },
  mixins: [api],
  computed: {
    dateFromString() {
      return this.dateFrom
        ? moment.utc(this.dateFrom).format(this.serverDateFormat)
        : null;
    },
    dateToString() {
      return this.dateTo
        ? moment.utc(this.dateTo).format(this.serverDateFormat)
        : null;
    },
  },
  methods: {
    loadData(sorter) {
      this.loading = true;
      this.apiGetAssociatePayCodes(
        this.selectedAssociateNumber,
        this.dateFromString,
        this.dateToString,
        this.pagination.current,
        this.pagination.pageSize,
        sorter
      )
        .then((resp) => {
          resp.body.shifts.forEach(({ shift, associate }) => {
            const dateMoment = moment
              .utc(shift.date)
              .tz(this.$store.state.applicationState.timeZone);

            shift.dateValueOf = dateMoment.valueOf();
            shift.date = dateMoment.format(
              this.$store.state.applicationState.dateFormat
            );
            shift.pay_code.type = shift.pay_code.type.toUpperCase();
            shift.pay_code.value =
              shift.pay_code.type.toLowerCase() === "total"
                ? Util.convertDbToMoney(shift.hour_rate)
                : shift.pay_code.value;
            shift.hour_duration = (shift.duration / 60).toFixed(2);
          });

          this.tableData = resp.body.shifts;

          this.pagination.total = resp.body.total;
        })
        .finally(() => (this.loading = false));
    },
    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value)
          .then((resp) => {
            this.foundAssociates = resp.data.associate_list.map((user) => ({
              label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
              key: user.employee_id,
              value: user.employee_id,
            }));
          })
          .catch((err) => console.error(err));
      }, 1000);
    },
    handleSearchUsersChange(value) {
      this.selectedAssociateNumber = value;
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    addCode() {
      this.visibleAssociatePayCodeModal = true;
    },
    editCode(shiftId) {
      this.selectedShiftId = shiftId;
      this.visibleAssociatePayCodeModal = true;
    },
    deleteCode(shiftId) {
      antd.Modal.confirm({
        title: "Are you sure?",
        content: "You're going to delete the Pay Code under Shift #" + shiftId,
        onOk: () => {
          this.apiDeleteAssociateShift(shiftId)
            .then(() => {
              notification["success"]({
                message: "Success",
                description:
                  "Pay Code under shift #" +
                  shiftId +
                  " was successfully deleted",
              });
              this.loadData();
            })
            .catch(() => {
              notification["error"]({
                message: "Error",
                description:
                  "An error occurred when deleting the shift. Please contact the administrator",
              });
            });
        },
        onCancel() {},
      });
    },

    handleAddOrEditAssociatePayCode() {
      this.visibleAssociatePayCodeModal = false;
      this.selectedShiftId = null;
      this.loadData();
    },

    closeAssociatePayCodeModal() {
      this.visibleAssociatePayCodeModal = false;
      this.selectedShiftId = null;
    },

    sendToFirstPage() {
      this.pagination.current = 1;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination };
      this.loadData(sorter);
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>
