<template>
  <a-modal
    width="600px"
    v-model="visibleModal"
    :footer="null"
    title="Create Write-Up"
    @cancel="$emit('close')"
  >
    <div class="content-view">
      <div class="content-view-block">
        <p>
          <b>Associate</b>
          <grouped-associate-info
            :first-name="associateFirstName"
            :last-name="associateLastName"
            :role="associateRole"
          />
        </p>
        <p>
          <b>Date</b>
          <a-date-picker
            class="writeup-selector"
            v-model="date"
            :format="dateFormat"
          />
        </p>
        <p>
          <b>Follow up review days</b>
          <a-input-number
            v-model="followUpReviewDays"
            class="writeup-selector"
            :min="1"
            :max="31"
            placeholder="Follow up review days"
          />
        </p>
        <p>
          <b>Type of disciplinary action</b>
          <a-select
            v-model="disciplinaryActionType"
            class="writeup-selector"
            placeholder="Select type"
            :options="disciplinaryActionTypes"
          />
        </p>
        <p>
          <b>Violation category</b>
          <a-select
            v-model="violationCategoryType"
            class="writeup-selector"
            placeholder="Select category"
            :options="violationCategoryTypes"
          />
        </p>
        <p>
          <b>Reason for counselling/corrective action</b>
          <a-input
            class="writeup-selector"
            v-model="reasonForAction"
            placeholder="Enter reason"
          />
        </p>
        <p>
          <b>Expected level of performance</b>
          <a-input
            class="writeup-selector"
            v-model="expectedLevelOfPerformance"
            placeholder="Enter level of performance"
          />
        </p>
        <div class="writeup-send">
          <a-button
            type="primary"
            :disabled="disabled"
            :loading="loading"
            @click="createWriteUp"
          >
            Send
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import api from "@/api";
import {
  Modal,
  DatePicker,
  Select,
  Input,
  InputNumber,
  Button,
  notification,
} from "ant-design-vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import { disciplinaryActionTypes, violationCategories } from "@/const";

export default {
  components: {
    "a-modal": Modal,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-button": Button,
    "grouped-associate-info": GroupedAssociateInfo,
  },
  mixins: [api],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    associateNumber: {
      type: String,
      required: true,
    },
    associateFirstName: {
      type: String,
      required: true,
    },
    associateLastName: {
      type: String,
      required: true,
    },
    associateRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      date: undefined,
      followUpReviewDays: undefined,
      disciplinaryActionType: undefined,
      violationCategoryType: undefined,
      reasonForAction: undefined,
      expectedLevelOfPerformance: undefined,

      loading: false,
      visibleModal: false,
    };
  },
  watch: {
    visible() {
      this.visibleModal = this.visible;
    },
  },
  computed: {
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    disciplinaryActionTypes() {
      return disciplinaryActionTypes;
    },
    violationCategoryTypes() {
      return violationCategories;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    convertedDate() {
      return this.date.format(this.serverDateFormat);
    },
    disabled() {
      return (
        !this.date ||
        !this.followUpReviewDays ||
        !this.disciplinaryActionType ||
        !this.violationCategoryType
      );
    },
  },
  methods: {
    createWriteUp() {
      this.loading = true;
      this.apiCreateWriteUp(
        this.associateNumber,
        this.convertedDate,
        this.followUpReviewDays,
        this.disciplinaryActionType,
        this.violationCategoryType,
        this.reasonForAction,
        this.expectedLevelOfPerformance
      )
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            notification["warning"]({
              message: "Write-Up Create Error",
              description: body.msg ?? "Please try again later",
            });
            return;
          }

          notification["success"]({
            message: "Write-Up Created Successfully",
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style scoped>
.content-view-block {
  max-width: unset;
}

.writeup-selector {
  width: 200px;
  max-width: 200px;
}

.writeup-send {
  margin: 0 auto;
}
</style>
