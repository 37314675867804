import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/api";
import AuthUtil from "@/auth.util"
import moment from "moment-timezone";
import InitialSettingsModalContent from "@/components/initial-settings-modal-content.vue";
import router from "@/router";

Vue.use(Vuex)
Vue.component('InitialSettingsModalContent', InitialSettingsModalContent);

export const applicationStateModule = {
  state: {
    user: null,
    associate: null,
    currentProject: null,
    currentPaymentType: null,
    currentPaymentTypeName: null,
    allProjects: null,
    dateFromFilter: null,
    dateToFilter: null,
    timeCardsSelectedEmployee: undefined,
    shiftAuthSelectedEmployee: undefined,
    authError: localStorage.getItem('authError') === 'true',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateFormat: "MM/DD/YYYY",
    dateTimeFormat: "MM/DD/YYYY hh:mm A",
    serverDateFormat: 'YYYY-MM-DD',
    timeFormat: "hh:mm A",
    isProd: true,
    shiftAuthTypes: {},
    extendedShiftAuthTypes: [],
    timeConstants: {},
    projectRoles: [],
    projectLegalEntities: [],
    scheduleTypes: [],
    paymentTypes: [],
    chartOptions: {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setAssociate(state, associate) {
      state.associate = associate;
    },
    setCurrentProject(state, currentProject) {
      state.currentProject = currentProject;
    },
    setAllProjects(state, allProjects) {
      state.allProjects = allProjects;
    },
    setAuthError(state, authError) {
      state.authError = authError;
      localStorage.setItem('authError', authError);
    },
    setDateFromFilter(state, dateFrom) {
      state.dateFromFilter = dateFrom;
    },
    setDateToFilter(state, dateTo) {
      state.dateToFilter = dateTo;
    },
    setTimeCardsSelectedEmployee(state, selectedEmployee) {
      state.timeCardsSelectedEmployee = selectedEmployee;
    },
    setShiftAuthSelectedEmployee(state, selectedEmployee) {
      state.shiftAuthSelectedEmployee = selectedEmployee;
    },
    setTimeZone(state, timeZone) {
      state.timeZone = timeZone;
      localStorage.setItem('timeZone', timeZone);
    },
    setIsProd(state, isProd) {
      state.isProd = isProd;
    },
    setCurrentPaymentType(state, paymentType) {
      state.currentPaymentType = paymentType;
    },
    setCurrentPaymentTypeName(state, paymentTypeName) {
      state.currentPaymentTypeName = paymentTypeName;
    },
    setShiftAuthTypes(state, types) {
      state.shiftAuthTypes = types;
    },
    setExtendedShiftAuthTypes(state, types) {
      state.extendedShiftAuthTypes = types;
    },
    setTimeConstants(state, constants) {
      state.timeConstants = constants;
    },
    setProjectRoles(state, roles) {
      state.projectRoles = roles;
    },
    setScheduleTypes(state, scheduleTypes) {
      state.scheduleTypes = scheduleTypes;
    },
    setPaymentTypes(state, paymentTypes) {
      state.paymentTypes = paymentTypes;
    },
    setProjectLegalEntities(state, legalEntities) {
      state.projectLegalEntities = legalEntities;
    }
  },
  actions: {
    loadTimeZone({commit, state}) {
      const timeZone = localStorage.getItem('timeZone');
      if (timeZone) {
        commit('setTimeZone', timeZone);
      }
      moment.tz.setDefault(state.timeZone);
    },
    async loadApplicationState({commit, state}) {
      this.dispatch('loadTimeZone');

      try {
        await Vue.http.post(api.methods.getApiCallAddress() + 'tc_get_user_info_state')
          .then((response) => {
            const body = response.body;

            if (!body.error_code || body.error_code != "0") {
              commit('setAuthError', true);
              AuthUtil.handleAuthError(body);
              return;
            }

            commit('setAuthError', false);
            const receivedState = body.state;

            const updateStateIfDifferent = (mutation, stateKey, newValue) => {
              if (JSON.stringify(state[stateKey]) !== JSON.stringify(newValue)) {
                JSON.stringify(state);
                commit(mutation, newValue);
              }
            };

            updateStateIfDifferent('setUser', 'user', receivedState.user);
            updateStateIfDifferent('setAssociate', 'associate', receivedState.associate);
            updateStateIfDifferent('setCurrentProject', 'currentProject', receivedState.current_project);
            updateStateIfDifferent('setAllProjects', 'allProjects', receivedState.all_projects);
            updateStateIfDifferent('setIsProd', 'isProd', receivedState.is_prod);
            updateStateIfDifferent('setProjectLegalEntities', 'projectLegalEntities', receivedState.legal_entities);
            updateStateIfDifferent('setCurrentPaymentType', 'currentPaymentType', receivedState.current_payment_type);
            updateStateIfDifferent('setCurrentPaymentTypeName', 'currentPaymentTypeName', receivedState.current_payment_type_name);

            const shiftAuthTypes = receivedState.shift_auth_types.reduce((obj, type) => {
              obj[type.key] = type.name;
              return obj;
            }, {});

            updateStateIfDifferent('setShiftAuthTypes', 'shiftAuthTypes', shiftAuthTypes);
            updateStateIfDifferent('setExtendedShiftAuthTypes', 'shiftAuthTypes', receivedState.shift_auth_types);

            updateStateIfDifferent('setTimeConstants', 'timeConstants', receivedState.time_constants);
            updateStateIfDifferent('setProjectRoles', 'projectRoles', receivedState.roles);
            updateStateIfDifferent('setScheduleTypes', 'scheduleTypes', receivedState.schedule_types);
            updateStateIfDifferent('setPaymentTypes', 'paymentTypes', receivedState.payment_types);

            localStorage.setItem('applicationState', JSON.stringify(state));
          })
      } catch (exception) {
        commit('setAuthError', true);


      }
    }
  }
}

export default new Vuex.Store({
  modules: {
    applicationState: applicationStateModule
  }
});
