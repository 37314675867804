function getPaymentStatusTagText(status) {
  switch (status) {
    case paymentStatusEnum.UNPAID:
      return "Payment for the shift is pending.";
    case paymentStatusEnum.PAID:
      return "Shift has been paid.";
    case paymentStatusEnum.NOT_SIGNED:
      return "Shift has not been signed. Click on the status to sign it";
    case paymentStatusEnum.AUTH_ISSUES:
      return "There are authorization issues with the shift.";
    case paymentStatusEnum.NOT_PAID:
      return "Shift has not been paid.";
    case paymentStatusEnum.CANCELLED:
      return "Payment was cancelled.";
    case paymentStatusEnum.IN_PROGRESS:
      return "Payment processing is in progress.";
    case paymentStatusEnum.DRAFT:
      return "Payment request is in draft status.";
    default:
      return "Unknown status.";
  }
}

function capitalizeWords(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

export const shiftStatusEnum = Object.freeze({
  SCHEDULED: "scheduled",
  STARTED: "started",
  MISSED: "missed",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
  DELETED: "deleted",
});

export const paymentStatusEnum = Object.freeze({
  UNPAID: "unpaid",
  PAID: "paid",
  NOT_SIGNED: "not_signed",
  AUTH_ISSUES: "auth_issues",
  NOT_PAID: "not_paid",
  CANCELLED: "cancelled",
  IN_PROGRESS: "in_progress",
  DRAFT: "draft",
});

export const statusTagColors = Object.freeze({
  GREEN: "#34db30",
  GRAY: "#b4b4b4",
  RED: "#ff4646",
  LILAC: "#6C69FF",
  TURTLE: "#21E29D",
  ORANGE: "#ffb546",
  BLUE_LIGHT: "#52bfff",
});

export const problematicAssociatesMap = Object.freeze({
  suspected: "Suspicious Time",
  with_writeups: "With Write-Ups",
  with_declined_auths: "With Declined Auths",
});

export const authorizationsMap = Object.freeze({
  no_lunch_authorization: { label: "No Meal Break", color: "#1abc9c" },
  no_second_lunch_authorization: {
    label: "No Second Meal Break",
    color: "#e67e22",
  },
  late_end_authorization: { label: "Late End", color: "#d35400" },
  early_start_authorization: { label: "Early Start", color: "#3498db" },
  early_lunch_end_authorization: {
    label: "Early Meal Break End",
    color: "#f1c40f",
  },
  early_second_lunch_end_authorization: {
    label: "Early Second Meal Break End",
    color: "#e74c3c",
  },
  no_actual_time_authorization: { label: "No Tracked Time", color: "#c0392b" },
  overtime_authorization: { label: "Unauthorized Work Time", color: "#7f8c8d" },
  edit_shift_authorization: { label: "Edit Shift", color: "#2ecc71" },
  shift_info: { label: "Shift Info", color: "#2ecc71" },
  outside_work_area_authorization: {
    label: "Outside of Work Area",
    color: "#34495e",
  },
  employee_issue: { label: "Employee Issue", color: "#2ecc71" },
  absence_on_shift_authorization: {
    label: "Absence on Shift",
    color: "#8e44ad",
  },
  late_start_authorization: { label: "Late Start", color: "#9b59b6" },
  early_end_authorization: { label: "Early End", color: "#95a5a6" },
  first_lunch_period_authorization: {
    label: "First Lunch Period Authorization",
    color: "#f1c40f",
  },
  signed_actual_time_discrepancy: {
    label: "Signed Actual Time Discrepancy",
    color: "#d35400",
  },
  no_rest_break_authorization: { label: "No Rest Break", color: "#d35400" },
  manually_shift_authorization: { label: "Manually Shift", color: "#c0392b" },
  associate_not_recognized_authorization: {
    label: "Associate Not Recognized",
  },
});

export const shiftStatusesMap = Object.freeze({
  scheduled: { label: "Scheduled", color: "#b4b4b4" },
  started: { label: "Started", color: "#52bfff" },
  missed: { label: "Missed", color: "#ff4646" },
  completed: { label: "Completed", color: "#34db30" },
  deleted: { label: "Deleted", color: "#ff4646" },
  cancelled: { label: "Cancelled", color: "#ff4646" },
  not_signed: { label: "Not Signed", color: "#ffb546" },
});

export const paymentStatusesMap = Object.freeze({
  unpaid: { label: "Unpaid", color: "#ff4646" },
  auth_issues: { label: "Auth Issues", color: "#ffb546" },
  not_paid: { label: "Ready To Pay", color: "#34db30" },
  draft: { label: "Draft Payment", color: "#52bfff" },
  in_progress: { label: "Payment In Progress", color: "#52bfff" },
  paid: { label: "Paid", color: "#b4b4b4" },
  cancelled: { label: "Payment Cancelled", color: "#ff4646" },
});

export const paymentRequestPaymentStatusesMap = Object.freeze({
  created: { label: "Created", color: "#9E9E9E" },
  processing: { label: "Processing", color: "#FFC107" },
  confirmed: { label: "Confirmed", color: "#03A9F4" },
  accepted: { label: "Accepted", color: "#4CAF50" },
  cancelled: { label: "Cancelled", color: "#F44336" },
  pending: { label: "Pending", color: "#FF9800" },
  completed: { label: "Completed", color: "#4CAF50" },
});

export const paymentMethodsMap = Object.freeze({
  check: { label: "Check", color: "#FF5722" },
  cash: { label: "Cash", color: "#4CAF50" },
  bank_transfer: { label: "Bank Transfer", color: "#03A9F4" },
  online_payment: { label: "Online Payment", color: "#FF9800" },
});

export const associateStatusMap = Object.freeze({
  scheduled_shift: "Scheduled Shift",
  work: "Work",
  lunch: "Lunch",
  second_lunch: "Second Lunch",
  complete_shift: "Complete Shift",
  mileage: "Tracking Mileage",
  missed: "Missed Shift",
  outside: "Outside",
});

export const associateStatusColorsMap = Object.freeze({
  scheduled_shift: "#808080",
  work: "#008000",
  lunch: "#008000",
  second_lunch: "#008000",
  complete_shift: "#008000",
  mileage: "#808080",
  missed: "#FF0000",
  outside: "#808080",
});

export const alertTypeMap = Object.freeze({
  validate_joined_associate: "Validate Associate",
  join_project_request: "Join Project Request",
  leave_project_request: "Leave Project Request",
  onboarding_completed: "Onboarding Completed",
  document_signed: "Document Signed",
  associate_left: "Associate Left",
});

export const alertSeverityMap = Object.freeze({
  normal: "Info",
  warning: "Warning",
  forbid_work: "Critical",
});

export const alertSeverityColorsMap = Object.freeze({
  normal: "#34db30",
  warning: "#ffb546",
  forbid_work: "#ff4646",
});

export const alertColorsMap = Object.freeze({
  scheduled_shift: "#808080",
  work: "#008000",
  lunch: "#008000",
  second_lunch: "#008000",
  complete_shift: "#008000",
  mileage: "#808080",
  missed: "#FF0000",
  outside: "#808080",
});

export const statusColors = {
  GRAY: [shiftStatusEnum.SCHEDULED],
  GREEN: [paymentStatusEnum.NOT_PAID, shiftStatusEnum.COMPLETED],
  RED: [
    shiftStatusEnum.MISSED,
    shiftStatusEnum.CANCELLED,
    shiftStatusEnum.DELETED,
    paymentStatusEnum.CANCELLED,
    paymentStatusEnum.UNPAID,
    shiftStatusEnum.CALL_OFF,
  ],
  ORANGE: [
    paymentStatusEnum.NOT_SIGNED,
    paymentStatusEnum.AUTH_ISSUES,
    paymentStatusEnum.DRAFT,
  ],
  BLUE_LIGHT: [
    shiftStatusEnum.STARTED,
    paymentStatusEnum.IN_PROGRESS,
    paymentStatusEnum.PAID,
  ],
};

export const timeOffRequestTypeMap = Object.freeze({
  vacation: "Vacation",
  sick: "Sick",
  cancelled: "Cancelled",
});

export const timeOffRequestTypeColorMap = Object.freeze({
  sick: "#F44336",
  vacation: "#03A9F4",
  cancelled: "#9E9E9E",
});

export const timeOffRequestSubtypeMap = Object.freeze({
  self: "Self",
  family: "Family",
  paid: "Paid",
  unpaid: "Unpaid",
  personal_reason: "Personal Reason",
  jury_duty: "Jury Duty",
  bereavement: "Bereavement",
  military_duty: "Military Duty",
});

export const timeOffRequestStatusMap = Object.freeze({
  pending: "Pending",
  approved: "Approved",
  declined: "Declined",
});

export const vueTourOptions = {
  highlight: true,
  labels: {
    buttonPrevious: "Back",
    buttonNext: "Next",
    buttonStop: "Finish",
    buttonSkip: "Skip Guide",
  },
};

export const agencyTypes = Object.freeze({
  billable_customer: "Billable Customer",
  independent_contractor: "Independent Contractor",
  agency: "Agency",
});

export const writeUpStatuses = Object.freeze({
  signed: { label: "Signed", color: "#4CAF50" },
  not_signed: { label: "Unsigned", color: "#9E9E9E" },
});

export const shiftAuthDescriptions = Object.freeze({
  no_lunch_authorization:
    "Authorization requested in case the person did not take meal break, although he should have done so",
  no_second_lunch_authorization:
    "Authorization requested in case the person did not take second meal break, although he should have done so",
  late_end_authorization:
    "Authorization requested in case the person has completed shift later than the time in the schedule",
  early_start_authorization:
    "Authorization requested in case the person has started shift early than the time in the schedule",
  early_lunch_end_authorization:
    "Authorization requested in case the person has finished meal break earlier than the meal break duration has passed",
  early_second_lunch_end_authorization:
    "Authorization requested in case the person has finished second meal break earlier than the meal break duration has passed",
  no_actual_time_authorization:
    "Authorization requested in case the persons shift has not clock in/out times",
  overtime_authorization:
    "Authorization requested in case of associate overtime",
  edit_shift_authorization:
    "Authorization requested in case the person has adjusted time with authorizations",
  shift_info:
    "Authorizations requested in case the person do not have shift in the schedule and persons schedule type is self schedule",
  outside_work_area_authorization:
    "Authorization requested in case the person has done action outside the work area specified in the project",
  employee_issue:
    "Authorization requested if the associate finished the shift with a issue",
  absence_on_shift_authorization:
    "Authorization is created when an associate is absent for a valid reason",
  late_start_authorization:
    "Authorization requested in case the person has started shift later than the time in the schedule",
  early_end_authorization:
    "Authorization requested in case the person has completed shift early than the time in the schedule",
  first_lunch_period_authorization:
    "Authorization requested in case the person has worked without break more than available work duration without break",
  signed_actual_time_discrepancy:
    "Authorization requested in case the person has signed shift with an actual time discrepancy",
  no_rest_break_authorization:
    "Authorization requested in case the person did not take rest break, although he should have done so",
  manually_shift_authorization:
    "Authorization requested in case the person do not have shift in the schedule and persons schedule type is authorized",
  associate_not_recognized_authorization:
    "Authorization requested in case the person face has not detected in photo of action in shift",
});

export const disciplinaryActionTypes = [
  {
    label: "Verbal Counselling",
    key: "verbal_counselling",
    value: "verbal_counselling",
  },
  {
    label: "Written Warning",
    key: "written_warning",
    value: "written_warning",
  },
  {
    label: "Final Written Warning",
    key: "final_written_warning",
    value: "final_written_warning",
  },
  {
    label: "Suspension",
    key: "suspension",
    value: "suspension",
  },
  {
    label: "Termination",
    key: "termination",
    value: "termination",
  },
];

export const violationCategories = [
  {
    label: "Attendance or Tardiness",
    key: "attendance_or_tardiness",
    value: "attendance_or_tardiness",
  },
  {
    label: "Work Quality",
    key: "work_quality",
    value: "work_quality",
  },
  {
    label: "Insubordination",
    key: "insubordination",
    value: "insubordination",
  },
  {
    label: "Safety",
    key: "safety",
    value: "safety",
  },
];

export const sequenceStepTypes = Object.freeze({
  sign_document: "Sign Document",
  attach_file: "Attach File",
  survey: "Survey",
});

export function getShiftStatusTagText(status) {
  switch (status) {
    case shiftStatusEnum.SCHEDULED:
      return "Shift has been scheduled.";
    case shiftStatusEnum.STARTED:
      return "Shift is currently in progress.";
    case shiftStatusEnum.MISSED:
      return "Shift has been missed.";
    case shiftStatusEnum.CANCELLED:
      return "Shift has been cancelled.";
    case shiftStatusEnum.DELETED:
      return "Shift has been deleted.";
    default:
      return getPaymentStatusTagText(status);
  }
}

export function getShiftStatusTagLabel(status) {
  switch (status) {
    case shiftStatusEnum.SCHEDULED:
      return "Scheduled";
    case shiftStatusEnum.STARTED:
      return "Started";
    case shiftStatusEnum.MISSED:
      return "Missed";
    case shiftStatusEnum.CANCELLED:
      return "Cancelled";
    case shiftStatusEnum.COMPLETED:
      return "Completed";
    case shiftStatusEnum.DELETED:
      return "Deleted";
    default:
      return tryToGetPaymentStatusTagText(status);
  }
}

export function tryToGetPaymentStatusTagText(status) {
  switch (status) {
    case paymentStatusEnum.UNPAID:
      return "Unpaid";
    case paymentStatusEnum.PAID:
      return "Paid";
    case paymentStatusEnum.NOT_SIGNED:
      return "Not Signed";
    case paymentStatusEnum.AUTH_ISSUES:
      return "Auth Issues";
    case paymentStatusEnum.NOT_PAID:
      return "Ready To Pay";
    case paymentStatusEnum.CANCELLED:
      return "Payment Cancelled";
    case paymentStatusEnum.IN_PROGRESS:
      return "Payment In Progress";
    case paymentStatusEnum.DRAFT:
      return "Draft Payment";
    default:
      return capitalizeWords(status);
  }
}

export function getAuthStatusTagColor(status) {
  const combinedStatusMap = { ...shiftStatusesMap, ...paymentStatusesMap };
  const lowercasedStatus = status.toLowerCase();

  if (combinedStatusMap[lowercasedStatus]) {
    return combinedStatusMap[lowercasedStatus].color;
  } else {
    return "#9E9E9E";
  }
}
